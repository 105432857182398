.Header {
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  margin: "30px 0";
  background: wheat;
  //font-style: italic;
}

.quote14 {
  font-size: 14px;
  white-space: pre-wrap;
}

.quote12 {
  font-size: 12px;
  white-space: pre-wrap;
}

.quote13 {
  font-size: 13px;
  white-space: pre-wrap;
}

.quote15 {
  font-size: 15px;
  white-space: pre-wrap;
}

.Title {
    background: khaki;
  }
  
.Title2 {
font-size: 13px;
background: khaki;
}

.Title16 {
  font-size: 16px;
  background: khaki;
  }

.QuoteOld {
  font-family: 'Fondamento';
  font-size: 18px;
  white-space: pre-wrap;
}

.Quote {
  font-family: 'Helvetica';
  font-size: 14px;
  white-space: pre-wrap;
}

.quote16 {
  font-size: 16px;
  white-space: pre-wrap;
}

.quote14 {
  font-size: 14px;
  white-space: pre-wrap;
}

.quote12 {
  font-size: 12px;
  white-space: pre-wrap;
}

.quote13 {
  font-size: 13px;
  white-space: pre-wrap;
}

.ArtTitle {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    /* height: 30px; */
    /* margin: "20px 0"; */
    background: khaki;
    /* font-style: italic; */
  }

.Description {
    font-size: 13px;
    white-space: pre-wrap;
}

.Description2 {
    font-size: 14px;
    white-space: pre-wrap;
}

.factText {
    background-color: #f1efef;
    font-size: 13px;
    white-space: pre-wrap;
  }

.SmallText {
    font-size: 12px;
  }

.SmallText-10 {
    font-size: 10px;
    font-style: italic;
  }

  .backgroud {
    background: khaki;
  }
  
  .background13 {
    font-size: 13px;
    background: khaki;
  }

  .Date {
    font-size: 13px;
    background: rgb(229, 231, 235);
  }
  
  .DateTxt {
    font-size: 13px;
    background: rgb(229, 231, 235);
    white-space: pre-wrap;
  }

  .SummaryChapter {
    font-size: 14px;
    /* font-weight: bold; */
    font-family: Arial, Helvetica, sans-serif;
    /* height: 30px;
    margin: "20px 0"; */
    background: rgb(235, 235, 232);
    /* font-style: italic; */
  }
  
  .SummaryChapter2 {
    font-size: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    background: rgb(235, 235, 232);
  }

  .center {
    text-align: center;
    /* border: 3px solid green; */
  }

  .YourHighlightClass {
    color: rgb(7, 7, 7);
    background-color: #07f713;
  }

  .blockquote {
    color: #666;
    margin: 0;
    padding-left: 2em;
    border-left: 0.2em #eee solid;
    font-size: 17px;
  }

  .blockquote-source {
    color: rgb(39, 38, 38);
    margin: 0;
    padding-left: 2em;
    font-style: italic;
    font-size: 14px;
    /* border-left: 0.2em #eee solid; */
  }

  .blockquote-source-11 {
    color: rgb(39, 38, 38);
    margin: 0;
    padding-left: 2em;
    font-style: italic;
    font-size: 11px;
    /* border-left: 0.2em #eee solid; */
  }

  .search-container {
    width: 222px;
    height: 51px;
    padding: 10px;
    background: #f3f3f3;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
   }